// Angular
import '@angular/platform-browser';
import '@angular/platform-browser-dynamic';
import '@angular/core';
import '@angular/common';
import '@angular/http';
import '@angular/router';

// RxJS
import 'rxjs';

// Other vendors for example jQuery, Lodash or Bootstrap
// You can import js, ts, css, sass, ...

// import "lodash";
// import "sugar";
// import "sugar/locales/de.js";
// import "sugar/locales/fr.js";

// icons
import 'material-design-icons-iconfont/dist/material-design-icons.css';
import '@mdi/font/css/materialdesignicons.min.css';

// quill
import 'quill/dist/quill.snow.css';

// ag-grid
import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-material.css';
// import 'ag-grid-angular/main';
// import 'ag-grid-enterprise/main';

